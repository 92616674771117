
.btnReveal{
  border-radius: 0px;
  border: solid 1px black;
  background-color: #ff00ff;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.5rem;
}


.btnDark{
  border-radius: 0px;
  border: solid 1px black;
  background-color:grey;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.5rem;
}

.infoWalletCon{
  width: 60%;
}
 
