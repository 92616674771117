.app-body-text1 {
  font-family: GilRoy-Medium;
  width: 60%;
  font-size: 35px;
}

.app-body-text2 {
  font-family: GilRoy;
  padding-top: 15px;
  padding-bottom: 40px;
  width: 70%;
  font-size: 14px;
}

.app-email-rq {
  width: 50%;
  display: flex;
}
.app-email-rq .app-email-input {
  border-radius: 0;
  border: solid 1px black;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-mail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-email-btns1 {
  border-radius: 0px;
  border: solid 1px black;
  background-color: #ff00ff;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.app-email-btns2 {
  border-radius: 0px;
  border: solid 1px black;
  background-color: black;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.app-email-btns2:hover,
.app-email-btns1:hover {
  color: white;
}

@media only screen and (max-width: 600px) {
  .app-email-rq {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
  }
  .app-email-rq .app-email-input {
    margin-bottom: 10px;
  }

  .app-email-rq {
    width: 90%;
    display: flex;
  }
}

.ErrorMensaje {
  font-size: 1rem;
  color: #ff00ff;
  letter-spacing: normal;
  padding-bottom: 10px;
}
