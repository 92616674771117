.StatisticContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Statistics {
  display: grid;
  width: 80%;
  grid-template-columns: auto auto;
  gap: 50px;
}

.StatisticsItem p:nth-child(1) {
  font-size: 1.5rem;
  color: #ff00ff;
}

.StatisticsItem p:nth-child(2) {
  font-size: 1.2rem;
  color: black;
}

@media only screen and (max-width: 600px) {
  .Statistics {
    display: grid;
    width: 80%;
    grid-template-columns: auto;
    gap: 50px;
  }
}

.form-pass {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.app-rq {
  width: 50%;
  display: flex;
}
.app-rq .app-input {
  border-radius: 0;
  border: solid 1px black;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 5px;
}


.app-btn {
    border-radius: 0px;
    border: solid 1px black;
    background-color: #ff00ff;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
.ErrorMensaje {
    font-size: 1rem;
    color: #ff00ff;
    letter-spacing: normal;
    padding-bottom: 10px;
  }
  