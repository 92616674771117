.listPlatzeeContainer {
  width: 80%;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.listPlatzeeContainerCol {
  width: 80%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.itemPlatzee {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  justify-content: center;
}

.itemPlatzee label:nth-child(1) {
  color: #ff00ff;
  font-size: 1.5rem;
}
.itemPlatzee label:nth-child(2) {
  font-size: 2rem;
}

.itemPlatzeebtn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.itemPlatzeebtn button {
  border-radius: 0px;
  border: solid 1px black;
  background-color: #ff00ff;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.5rem;
}

.itemReveal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.itemRevealCongrats {
  font-weight: bold;
  font-size: 2.5rem;
}

.OrderNumberReveal {
  font-family: "VT323", monospace;
  letter-spacing: 20px;
  font-size: 100px;
  color: #ff00ff;
}

.itemRevealInfo {
  width: 50%;
  font-size: 1rem;
}

.loadingItem {
  padding-top: 30px;
}

.containerInfo {
  padding-top: 30px;
  width: 60%;
  padding-bottom: 40px;
}

.seccionInfo .seccionSubtitulo {
  font-family: GilRoy-Medium;
  font-size: 1.5rem;
  font-weight: bolder;
  color: #ff00ff;
}

.seccionInfo .seccionTitulo {
  font-family: GilRoy-Medium;
  font-size: 1.8rem;
  font-weight: bolder;
}

.seccionInfo .seccionDescripcion {
  font-size: 1.5rem;
}

.seccionInfo .seccionDescripcion a {
  font-family: GilRoy-Medium;
  text-decoration: none;
  color: #ff00ff;
  margin-left: 10px;
}

.seccionInfo .seccionDescripcion a:hover {
  font-size: 1.6rem;
  transition: 1s;
}

.seccionVideo {
  min-height: 500px;
  padding-bottom: 50px;
  padding-top: 50px;
}

.seccionVideo iframe {
  width: 100%;
  height: 500px;
}

.seccionInfo .seccionDescripcionLight {
  font-size: 1.1rem;
}

.containerConnect {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 50px;
}

.containerConnect p {
  font-size: 2rem;
}

.containerConnect p:nth-child(1) {
  color: #ff00ff;
  font-size: 1rem;
  width: 50%;
}
.containerConnect p:nth-child(2) {
  font-weight: 900;
}

.listAssignedPlatzeesCont {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.listAssignedPlatzeesCont label {
  color: #ff00ff;
  font-size: 1.5rem;
  padding-bottom: 20px;
}
.listAssignedPlatzees3 {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
}

.listAssignedPlatzees1 {
  display: grid;
  grid-template-columns: auto;
  width: 100%;
}

.listAssignedPlatzees1 > p > a,
.listAssignedPlatzees3 > p > a {
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
}
@media only screen and (max-width: 600px) {
  .itemRevealInfo {
    width: 100%;
    font-size: 1.1rem;
  }
  .containerInfo {
    padding-top: 30px;
    width: 90%;
    padding-bottom: 40px;
  }

  .containerConnect p:nth-child(1) {
    color: #ff00ff;
    font-size: 1rem;
    width: 90%;
  }
}
