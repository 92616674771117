.App {
  height: 100vh;
  text-align: center;
}

.App-body {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-control {
  box-shadow: none;
  border-radius: 0px;
}

.form-control:focus {
  border-color: black;
  box-shadow: none;
  border-radius: 0px;
}

.btn:focus,
.btn-primary:focus {
  box-shadow: none;
}

.App-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 60px;
  padding-top: 50px;
}

.App-Header div:nth-child(1) img {
  width: 30px;
  height: 30px;
  background-color: black;
  cursor: pointer;
}
.App-HeaderImg {
  max-width: 200px;
}

.img-clickable{
  cursor: pointer;
}

@media only screen and (min-width: 769px) {
  body {
    font-size: 30px;
  }
}

.footerMessage {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: black;
  align-items: center;
  font-size: 1.2rem;
}

.footerMessage a{
  text-decoration: none;
  color: #ff00ff;
  font-weight: 900;
}
.footerMessage a:hover{
  transition: 1s;
  font-size: 1.4rem;
}

.App-background {
  background-image: url("./assets/imagenFondo.png");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 50% 95%;
}

@media only screen and (max-width: 600px) {
  .App {
    background-size: 100%;
  }
}
