
@font-face {
  font-family: GilRoy;
  src: url('./assets//fonts/Gilroy-Regular.ttf');
}

@font-face {
  font-family: GilRoy-Medium;
  src: url('./assets//fonts/Gilroy-Medium.ttf');
}

body {
  margin: 0;
  font-family: GilRoy;
  background-image: url("./assets/Fondo@150x.png");
 
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
